import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { loadWorkspaceUsers, transferWorkspaceOwnership, updateWorkspace } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import { WorkspaceUserSchema } from '../../../api/schemas/workspaceUserSchema';
import ConfirmDialog from '../../../components/ConfirmDialog';
import Form from '../../../components/Form';
import Id from '../../../components/Id';
import Input from '../../../components/Input';
import Select, { Option } from '../../../components/Select';
import { formatRelativeDate } from '../../../utils/date';
import { maxLength, minLength, required } from '../../../utils/form';
import styles from './styles.module.scss';

interface Fields {
  name: string;
  new_owner_id: Option<WorkspaceUserSchema['id']>;
}

interface Props {
  data: WorkspaceSchema;
  disabled: boolean;
}

const WorkspaceSettingsGeneralForm = (props: Props) => {
  const { data, disabled } = props;

  const { id, created_on } = data;

  const { t } = useTranslation();

  const [transfer, setTransfer] = useState<Option>(null);

  const form = useForm<Fields>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      name: data.name,
      new_owner_id: { value: data.owner_id, label: data.owner_username || '' },
    },
  });

  const { control, handleSubmit, watch, setValue } = form;

  const onNameSubmit = (values: Fields) => {
    updateWorkspace({ workspaceId: data.id, payload: { name: values.name } });
  };

  const onOwnerSubmit = (values: Fields) => {
    if (!values.new_owner_id) throw new Error();

    const payload = { new_owner_id: values.new_owner_id.value };

    transferWorkspaceOwnership({ workspaceId: data.id, payload });
  };

  return (
    <>
      {transfer && (
        <ConfirmDialog
          danger
          title={t('common.confirm_ownership_transfer')}
          description={
            <>
              {t('sentences.workspace_transfer_1')}
              &nbsp;
              <span className={styles.bold}>{watch().new_owner_id?.label}</span>
              {t('sentences.workspace_transfer_2')}
            </>
          }
          confirmText={t('common.confirm')}
          onConfirm={handleSubmit(onOwnerSubmit)}
          onClose={() => {
            setValue('new_owner_id', transfer);

            setTransfer(null);
          }}
        />
      )}
      <Form form={form} className={styles.form}>
        <div className={styles.label}>{t('common.name')}</div>
        <Input.Quick
          name="name"
          control={control}
          disabled={disabled}
          onSubmit={handleSubmit(onNameSubmit)}
          rules={{ validate: { required, minLength: minLength(), maxLength: maxLength() } }}
        />
        <div className={styles.label}>{t('common.owner')}</div>
        <Select.AsyncV2
          onLoad={(args) => loadWorkspaceUsers({ workspaceId: data.id }, args)}
          select={(item) => ({ value: item.id, label: item.username })}
        >
          {(selectAsyncProps) => (
            <Select.Quick
              name="new_owner_id"
              control={control}
              disabled={disabled}
              onSubmit={() => setTransfer(watch().new_owner_id)}
              {...selectAsyncProps}
            />
          )}
        </Select.AsyncV2>
        <div className={styles.label}>{t('common.id')}</div>
        <div className={styles.value}>
          <Id variant="primary" id={id} />
        </div>
        <div className={styles.label}>{t('common.created')}</div>
        <div className={styles.value}>{formatRelativeDate(created_on)}</div>
      </Form>
    </>
  );
};

export default WorkspaceSettingsGeneralForm;
