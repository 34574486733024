import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TRAINER_LIMITS } from '../../api/schemas/trainerLimitSchema';
import Button from '../../components/Button';
import Select from '../../components/Select';
import { required } from '../../utils/form';
import { TRAINER_LIMITS_LABELS } from '../../utils/trainer';
import { getLimitBlind } from './helpers';
import { CommonAccountFields } from './types';

interface Props {
  required?: boolean;
}

const AccountFormLimitIds = (props: Props) => {
  const { required: req } = props;

  const { t } = useTranslation();

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CommonAccountFields>();

  const limitIds = watch('comparative_limits');

  const selectOptions = Object.values(TRAINER_LIMITS)
    .map((item) => ({
      value: item,
      label: TRAINER_LIMITS_LABELS[item],
      caption: getLimitBlind(item),
    }))
    .filter((item) => item.value !== TRAINER_LIMITS.UNKNOWN);

  const indeterminate = limitIds.length !== selectOptions.length;

  return (
    <Controller
      name="comparative_limits"
      control={control}
      {...(req && { rules: { validate: required } })}
      render={({ field }) => (
        <Select.Multi
          renderValueAsTag
          label={t('common.limits')}
          options={selectOptions}
          value={field.value}
          onChange={field.onChange}
          error={errors.comparative_limits?.message}
          {...(selectOptions.length > 0 && {
            addon: (
              <Button
                size="extra-small"
                variant="light"
                onClick={() => {
                  setValue('comparative_limits', indeterminate ? selectOptions : []);
                }}
              >
                {indeterminate ? t('common.select_all') : t('common.deselect_all')}
              </Button>
            ),
          })}
        />
      )}
    />
  );
};

export default AccountFormLimitIds;
