import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createWorkspaceWallet, deleteWorkspaceWallet, updateWorkspaceWallet } from '../../../api';
import { WorkspaceSchema } from '../../../api/schemas/workspaceSchema';
import { TYPE, WorkspaceWalletSchema } from '../../../api/schemas/workspaceWalletSchema';
import Button from '../../../components/Button';
import Confirm from '../../../components/Confirm';
import Input from '../../../components/Input';
import Tooltip from '../../../components/Tooltip';
import icon from '../../../static/icons';
import TetherIcon from '../../../static/wallets/tether.svg';
import { required, handleError } from '../../../utils/form';
import WorkspaceSettingsField from '../WorkspaceSettingsField';
import styles from './styles.module.scss';

interface Fields {
  address: string;
}

interface Props {
  workspaceId: WorkspaceSchema['id'];
  data?: WorkspaceWalletSchema;
  onRemove?: () => void;
}

const WorkspaceSettingsWalletsItem = (props: Props) => {
  const { workspaceId, data, onRemove } = props;

  const { t } = useTranslation();

  const { control, handleSubmit, setError } = useForm<Fields>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      address: data?.address || '',
    },
  });

  const onSubmit = async (values: Fields) => {
    try {
      const payload = { address: values.address };

      if (data) {
        updateWorkspaceWallet({ workspaceId, walletId: data.id, payload });
      } else {
        await createWorkspaceWallet({ workspaceId, payload: { ...payload, type: TYPE.USDT } });

        if (onRemove) onRemove();
      }
    } catch (error) {
      handleError({ error, setError });
    }
  };

  return (
    <WorkspaceSettingsField>
      <Button
        size="small"
        variant="transparent"
        className={styles.type}
        icon={<TetherIcon width={16} height={16} className={styles.icon} data-icon />}
      />
      <Input.Quick
        name="address"
        control={control}
        rules={{ validate: { required } }}
        autoFocus={!data}
        onSubmit={handleSubmit(onSubmit)}
      />
      <Tooltip label={t('common.delete')}>
        <Confirm
          danger
          confirmText={t('common.delete')}
          onConfirm={async () => {
            if (data) await deleteWorkspaceWallet({ workspaceId, walletId: data.id });

            if (onRemove) onRemove();
          }}
        >
          {({ confirming }) => (
            <Button
              danger
              size="small"
              variant="transparent"
              focused={confirming}
              icon={icon('trash', 16)}
            />
          )}
        </Confirm>
      </Tooltip>
    </WorkspaceSettingsField>
  );
};

export default WorkspaceSettingsWalletsItem;
