import { useTranslation } from 'react-i18next';
import { GlobalAccountSchema } from '../../api/schemas/account/globalAccountSchema';
import { GlobalUserSchema } from '../../api/schemas/globalUserSchema';
import FadingText from '../../components/FadingText';
import Link from '../../components/Link';
import PageAddon from '../../components/PageAddon';
import Tooltip from '../../components/Tooltip';
import { copyPid } from '../../utils/clipboard';
import { formatAbsoluteDate } from '../../utils/date';
import { TRAINER_POKER_ROOM_LABELS } from '../../utils/trainer';
import AccountBotSettings from '../account/AccountBotSettings';
import AccountChannels from '../account/AccountChannels';
import AccountGeolocation from '../account/AccountGeolocation';
import AccountRoomConfig from '../account/AccountRoomConfig';
import AccountStageTooltip from '../account/AccountStageTooltip';
import AccountStatus from '../account/AccountStatus';
import { getGameTypes, getLimits, getTableSizes } from '../account/helpers';
import ClubPageAddonTags from '../club/ClubPageAddonTags';
import { can } from '../permission/helpers';
import { getTitle } from '../session/helpers';
import GlobalAccountRoomConfigLoader from './GlobalAccountRoomConfig/GlobalAccountRoomConfigLoader';
import GlobalAccountStrategyProfile from './GlobalAccountStrategyProfile';

interface Props {
  data: GlobalAccountSchema;
  onRoomConfig: () => void;
  getUserRoute?: (args: { userId: GlobalUserSchema['id'] }) => string;
}

const GlobalAccountDetailDetailsTab = (props: Props) => {
  const { data, onRoomConfig, getUserRoute } = props;

  const { t } = useTranslation();

  const {
    id,
    maintenance,
    stage,
    room,
    max_tables,
    pid,
    user,
    email,
    affiliate_reference_number,
    next_session,
    last_online,
    last_played,
    created_on,
    modified_on,
  } = data;

  const getUser = () => {
    if (!user) return '—';

    const content = user.username;

    if (can.user.read && getUserRoute) {
      return <Link to={getUserRoute({ userId: user.id })}>{content}</Link>;
    }

    return content;
  };

  return (
    <PageAddon.Fields>
      <PageAddon.Id id={id} />
      <PageAddon.Field label={t('common.status')}>
        <AccountStatus data={data} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.workspace')}>
        <FadingText text={maintenance.name} tooltip={false} />
      </PageAddon.Field>
      {stage && (
        <PageAddon.Field label={t('common.stage')}>
          <Tooltip label={<AccountStageTooltip data={stage} />}>
            <span>{stage.name}</span>
          </Tooltip>
        </PageAddon.Field>
      )}
      <PageAddon.Field label={t('common.poker_room')}>
        {TRAINER_POKER_ROOM_LABELS[room]}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.clubs')}>
        <ClubPageAddonTags data={data.clubs} stoppedClubs={data.stopped_clubs} />
      </PageAddon.Field>
      <AccountChannels data={data} />
      <PageAddon.Field label={t('common.game_types')}>
        <PageAddon.Tags>{getGameTypes(data)}</PageAddon.Tags>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.limits')}>
        <PageAddon.Tags>{getLimits(data)}</PageAddon.Tags>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.table_size')}>
        <PageAddon.Tags>{getTableSizes(data)}</PageAddon.Tags>
      </PageAddon.Field>
      <PageAddon.Field label={t('common.max_tables')}>{max_tables}</PageAddon.Field>
      <PageAddon.Field label={t('common.pid')}>
        {pid ? <PageAddon.Copy onClick={() => copyPid(pid)}>{pid}</PageAddon.Copy> : '—'}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.geolocation')}>
        <AccountGeolocation data={data} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.next_session')}>
        {next_session ? getTitle({ start: next_session.start, end: next_session.end }) : '—'}
      </PageAddon.Field>
      <PageAddon.Collapse label={t('common.strategy_profile')}>
        <GlobalAccountStrategyProfile account={data} />
      </PageAddon.Collapse>
      {can.account.emailRead && (
        <PageAddon.Field label={t('common.email')}>
          <FadingText text={email || '—'} />
        </PageAddon.Field>
      )}
      {can.account.roomConfigRead && (
        <PageAddon.Collapse label={t('common.poker_room_config')}>
          <GlobalAccountRoomConfigLoader addon accountId={id}>
            {(args) => <AccountRoomConfig data={args.data} account={data} onEdit={onRoomConfig} />}
          </GlobalAccountRoomConfigLoader>
        </PageAddon.Collapse>
      )}
      <PageAddon.Field label={t('common.bot_settings')}>
        <AccountBotSettings data={data} />
      </PageAddon.Field>
      <PageAddon.Field label={t('common.member')}>{getUser()}</PageAddon.Field>
      <PageAddon.Field.AffiliateReference value={affiliate_reference_number} />
      <PageAddon.Field label={t('common.last_online')}>
        {formatAbsoluteDate(last_online)}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.last_played')}>
        {formatAbsoluteDate(last_played)}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.modified')}>
        {formatAbsoluteDate(modified_on)}
      </PageAddon.Field>
      <PageAddon.Field label={t('common.created')}>
        {formatAbsoluteDate(created_on)}
      </PageAddon.Field>
    </PageAddon.Fields>
  );
};

export default GlobalAccountDetailDetailsTab;
