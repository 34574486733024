import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { STATUS } from '../../../api/schemas/channelSchema';
import PageAddon from '../../../components/PageAddon';
import Tag from '../../../components/Tag';
import Tooltip from '../../../components/Tooltip';
import { CommonAccountSchema } from '../types';
import styles from './styles.module.scss';

const Item = (props: { data: CommonAccountSchema['channels'][number] }) => {
  const { data } = props;

  const { t } = useTranslation();

  const inactive = data.status === STATUS.UNKNOWN || data.status === STATUS.INACTIVE;

  const [fading, setFading] = useState(false);

  const label = (
    <>
      {inactive ? (
        <span className={styles.inactive}>{t('common.inactive')}</span>
      ) : (
        <span className={styles.active}>{t('common.active')}</span>
      )}
    </>
  );

  return (
    <Tooltip label={!fading && label}>
      <Tag secondary={inactive}>
        <Tag.FadingText
          text={data.host}
          onChange={setFading}
          tooltipLabel={
            <span className={styles.tooltipLabel}>
              {data.host}
              &nbsp;•&nbsp;
              {label}
            </span>
          }
        />
      </Tag>
    </Tooltip>
  );
};

interface Props {
  data: CommonAccountSchema;
}

const AccountChannels = (props: Props) => {
  const { data } = props;

  const { channels } = data;

  const { t } = useTranslation();

  return (
    <PageAddon.Field label={t('common.channel')}>
      {channels.length ? (
        <PageAddon.Tags>
          {channels.map((item) => (
            <Item key={item.id} data={item} />
          ))}
        </PageAddon.Tags>
      ) : (
        '—'
      )}
    </PageAddon.Field>
  );
};

export default AccountChannels;
