import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  shift,
  useFloating,
} from '@floating-ui/react';
import clsx from 'clsx';
import { forwardRef, ChangeEvent, useCallback, useState, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { getFontShorthand, measureText } from 'update-input-width';
import Error from '../Error';
import Label, { getLabelProps } from '../Label';
import InputButton from './InputButton';
import InputClearButton from './InputClearButton';
import InputQuick from './InputQuick';
import styles from './styles.module.scss';
import { Props } from './types';

const Input = Object.assign(
  forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {
      type,
      icon,
      label,
      error,
      placeholder,
      addon,
      extra,
      size = 'large',
      className: customClassName,
      children,
      setInputWidth,
      format,
      patternFormat,
      autoComplete = 'off',
      errorVariant = 'default',
      allow,
      onChange,
      ...rest
    } = props;

    const className = clsx(styles.input, {
      [styles[size]]: true,
      [styles.error]: error,
      [styles.withIcon]: icon,
      ...(errorVariant === 'popover' && { [styles.errorInputPopover]: true }),
      ...(customClassName && { [customClassName]: true }),
    });

    const arrowRef = useRef(null);

    const { refs, floatingStyles, context } = useFloating({
      open: true,
      placement: 'top',
      whileElementsMounted: autoUpdate,
      middleware: [offset(8), flip(), shift({ padding: 4 }), arrow({ element: arrowRef })],
    });

    const [width, setWidth] = useState<number | null>(null);

    const callbackRef = useCallback(
      (node: HTMLInputElement) => {
        if (setInputWidth && node) {
          const contentWidth = measureText(node.value, getFontShorthand(node));

          if (contentWidth) {
            setWidth(setInputWidth(contentWidth));
          }
        }
      },
      [setInputWidth]
    );

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        if (allow) {
          const eventValue = event.target.value;

          if (allow(eventValue) || eventValue === '') {
            return onChange(event);
          }

          return null;
        }

        return onChange(event);
      }

      return event;
    };

    const getContent = () => {
      if (format) {
        const {
          thousandSeparator = ',',
          decimalScale = 0,
          allowNegative = true,
          prefix,
          onValueChange,
        } = format;

        return (
          <NumericFormat
            autoComplete={autoComplete}
            allowNegative={allowNegative}
            getInputRef={ref}
            valueIsNumericString
            placeholder={placeholder}
            decimalScale={decimalScale}
            thousandSeparator={thousandSeparator}
            allowLeadingZeros={false}
            prefix={prefix}
            onValueChange={(values) => onValueChange(values.value)}
            {...rest}
          />
        );
      }

      if (patternFormat) {
        const { pattern, mask, allowEmptyFormatting, isAllowed, onValueChange } = patternFormat;

        return (
          <PatternFormat
            autoComplete={autoComplete}
            getInputRef={ref}
            format={pattern}
            mask={mask}
            isAllowed={isAllowed}
            placeholder={placeholder}
            allowEmptyFormatting={allowEmptyFormatting}
            onValueChange={(values) => onValueChange(values.value)}
            {...rest}
          />
        );
      }

      return (
        <input
          ref={mergeRefs([
            ref,
            callbackRef,
            ...(errorVariant === 'popover' ? [refs.setReference] : []),
          ])}
          type={type}
          data-input
          autoComplete={autoComplete}
          placeholder={placeholder}
          onChange={handleChange}
          {...(width && { style: { width: `${width}px` } })}
          {...rest}
        />
      );
    };

    return (
      <div className={styles.container}>
        {(label || addon) && <Label addon={addon} {...getLabelProps(label)} />}
        <div className={className}>
          <div className={styles.icon}>{icon}</div>
          {getContent()}
          {extra && (
            <div className={styles.extra} data-extra>
              {extra}
            </div>
          )}
          {children}
        </div>
        {errorVariant === 'default' && <Error error={error} />}
        {errorVariant === 'popover' && error && (
          <div ref={refs.setFloating} style={floatingStyles} className={styles.errorPopover}>
            <FloatingArrow
              ref={arrowRef}
              context={context}
              tipRadius={1}
              className={styles.arrow}
            />
            {error}
          </div>
        )}
      </div>
    );
  }),
  {
    Button: InputButton,
    ClearButton: InputClearButton,
    Quick: InputQuick,
  }
);

export default Input;
