import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import Select from '../Select';
import { Props as SelectProps } from '../types';
import styles from './styles.module.scss';

type Props<T extends FieldValues, Name extends string> = UseControllerProps<T> &
  SelectProps<FieldValues[Name]> & {
    onSubmit: () => void;
  };

const SelectQuick = <T extends FieldValues, Name extends string>(props: Props<T, Name>) => {
  const { name, control, rules, onSubmit, label, options, disabled, ...rest } = props;

  const {
    field,
    formState: { errors, isValid },
  } = useController({ name, control, rules });

  if (disabled) return <div className={styles.value}>{field.value.label}</div>;

  const error = errors[name];

  return (
    <Select
      quick
      label={label}
      clearable={false}
      options={options}
      value={field.value}
      onChange={(value) => {
        if (isValid) onSubmit();

        field.onChange(value);
      }}
      {...(error && { error: String(error.message) })}
      {...rest}
    />
  );
};

export default SelectQuick;
